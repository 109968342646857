import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import MetaPixel from "../../utils/metaPixel";
import { useMediaQuery } from "react-responsive";
import cn from "classnames";
import Tokenomics from "components/Tokenomics";
import SpreadTehWord from "components/SpreadTheWord";
import TokenMeter from "components/TokenMeter";
import image_1_1 from "assets/images/main/presale/presale-1m.svg";

import image_3_1 from "assets/images/main/presale/3_1.png";
import anim_3_1 from "assets/images/main/presale/3_1.mp4";

import TermsAndCondition from "assets/docs/Terms of Service Ozak AI.pdf";
import { BASE_COLOR, HIGHLIGHT_COLOR, POLLING_INTERVAL } from "utils/constants";
import { getPresaleStats } from "services/api";
import { formatToMB } from "utils/helper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BUY_NOW_URL } from "utils/config/app.config";
// import Disclaimer from "assets/docs/Terms of Service Ozak AI.pdf";
import Disclaimer from "assets/docs/Disclaimers Ozak AI.pdf";

const PresalePage = () => {
  // google analytic code
  ReactGA.send({
    hitType: "pageview",
    page: "/Presale",
    title: "Presale",
  });
  const isTablet = useMediaQuery({ maxWidth: 960 });
  const [presSaleStats, setPresaleStats] = useState({});

  const updateData = async () => {
    try {
      const data = await getPresaleStats();
      setPresaleStats(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    updateData().catch((err) => console.error(err));
    const interval = setInterval(() => {
      updateData().catch((err) => console.error(err));
    }, POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full h-full flex flex-col presale">
      <div className="bg-[url('assets/images/main/common/page-hero-bg.svg')]  bg-cover bg-center bg-no-repeat min-h-[755px] pt-[50px]">
        <div className="flex justify-center">
          <div className="inline-block bg-[#ffffff09] bg-contain bg-no-repeat rounded-full px-2 py-1">
            <h4 className="text-white font-[archivo] font-medium text-[48px] leading-[40px] px-3">
              <span className="mr-1">
                Presale is
              </span>
              <span className="text-[#38d974]">
                Live
              </span>
            </h4>
          </div>
        </div>

        {/* Show only if Usd Raised so far is greater than 1M */}
        {presSaleStats.usdRaisedSoFar > 1e9 ? (<div className="pb-12">
          <h1 className="text-center font-bold text-[184px] leading-[250px] tracking-[-0.02em] text-white">
            <span className="font-['Inter'] presale-bg-shadow">{presSaleStats.usdRaisedSoFar ? `$${formatToMB(presSaleStats.usdRaisedSoFar)}` : (
              <Skeleton
                width={200}
                height={48}
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />)}</span>
          </h1>
          <h4 className="text-center font-[archivo] font-medium text-[32px] leading-[10px] tracking-[-0.02em] text-white">
            Raised So Far.
          </h4>
        </div>) :
          (<div className="text-center pt-8 pb-4">
            <a href={BUY_NOW_URL} target="_blank"
              className="inline-block py-2 px-8 bg-white text-[#000000] text-[32px] font-medium rounded-[32px] w-fit cursor-pointer"
            >
              Buy Now
            </a>
          </div>)}

        <div className="py-10 px-6 md:px-10 lg:px-28">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
            <div className="box-raised">
              <div className="font-[archivo] text-[28px] font-medium text-white">
                {presSaleStats.currentPhase ? presSaleStats.currentPhase : (
                  <Skeleton
                    width={200}
                    height={24}
                    baseColor={BASE_COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                )}
              </div>
              <div className="text-color-light font-[HelveticaNeueLight] font-normal text-white text-[18px] leading-[28.8px]">
                Current Phase
              </div>
            </div>
            <div className="box-raised">
              <div className="font-[archivo] text-[28px] font-medium text-[#25D366]">
                {presSaleStats.currentPhase ? `$${presSaleStats.currentPrice}` : (
                  <Skeleton
                    width={200}
                    height={24}
                    baseColor={BASE_COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                )}
              </div>
              <div className="text-color-light font-[HelveticaNeueLight] font-normal text-white text-[18px] leading-[28.8px]">
                Current Price
              </div>
            </div>
            <div className="box-raised">
              <div className="font-[archivo] text-[28px] font-medium text-white">
                $ 0.001
              </div>
              <div className="text-color-light font-[HelveticaNeueLight] font-normal text-white text-[18px] leading-[28.8px]">
                Starting Price
              </div>
            </div>
            <div className="box-raised">
              <div className="font-[archivo] text-[28px] font-medium text-white">
                $ 1
              </div>
              <div className="text-color-light font-[HelveticaNeueLight] font-normal text-white text-[18px] leading-[28.8px]">
                Target Price
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          {/* <div className="box-raised"> */}
          <TokenMeter presSaleStats={presSaleStats}></TokenMeter>
          {/* </div> */}
        </div>
      </div>

      <div className="pt-32 pb-12 px-6 md:px-10 lg:px-28">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-center font-[archivo] font-medium text-[64px] leading-[80px] text-white">
            Refers to Win
          </h2>
          <div className="text-center font-[HelveticaNeueLight] font-normal text-color-semilight text-[18px] leading-[25px] text-white">
            It’s your opportunity to win a share of the huge $1 Million Giveaway and a <br />
            chance to be one of the 100 winners sharing our massive
          </div>
        </div>
        <div className="flex justify-center items-center w-full h-full object-cover">
          <img src={image_1_1} alt="" />
        </div>
      </div>

      <div className="pb-32 pt-12 px-6 md:px-8 lg:px-32">
        <div className="flex flex-row items-center justify-center join-ozak xs:flex-col-reverse lg2:flex-row">
          <div className="basis-2/3">
            <h3 className="text-left font-[archivo] font-bold text-[48px] leading-[64.8px] text-white">
              Join the Ozak <br />AI's thrilling giveaway!
            </h3>
            <p className="text-left mt-4 text-color-semilight text-[18px] text-white max-w-[770px]">
              It’s your opportunity to win a share of the huge $1 Million Giveaway and a chance to
              be one of the 100 winners sharing our massive prize pool! Simply engage with us, complete
              as many tasks and boost your chances of winning with bonus entries for each friend you
              bring on board. Remember, participating in the Ozak AI's presale is mandatory in order
              to unlock the chance of being one of the 100 winners, make sure you hold at least $100
              worth of $OZ.
            </p>
            <div className="flex gap-2 items-center justify-start mt-8">
              <a className="btn-2" href={TermsAndCondition} target="_blank">
                Terms and Condition
              </a>
              <a className="btn-2" href={Disclaimer} target="_blank">
                Disclaimers
              </a>
            </div>
          </div>
          <div className="basis-1/3">
            <video poster={image_3_1} autoPlay loop muted className="w-full h-full object-cover">
              <source src={anim_3_1} type="video/mp4" />
              <source src={anim_3_1} type="video/ogg" />
              <source src={anim_3_1} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <Tokenomics></Tokenomics>

      <div className="flex items-center justify-center bg-[url('assets/images/main/common/spread-the-word.svg')] bg-cover bg-top py-32 px-6 md:px-10 lg:px-28 min-h-[789px] xs:min-h-[482px]">
        <div className="container mx-auto">
          <SpreadTehWord />
        </div>
      </div>

      <MetaPixel />
    </div>
  );
};
export default PresalePage;
